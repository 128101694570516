var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Form Data Karyawan"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nama Lengkap"},model:{value:(_vm.formSubmit.name),callback:function ($$v) {_vm.$set(_vm.formSubmit, "name", $$v)},expression:"formSubmit.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nama Lengkap Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat Lengkap"}},[_c('validation-provider',{attrs:{"name":"Alamat Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Alamat Lengkap"},model:{value:(_vm.formSubmit.address),callback:function ($$v) {_vm.$set(_vm.formSubmit, "address", $$v)},expression:"formSubmit.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Alamat Lengkap Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Pilih Tanggal Lahir","config":_vm.configs.allowInput},model:{value:(_vm.formSubmit.date_of_birth),callback:function ($$v) {_vm.$set(_vm.formSubmit, "date_of_birth", $$v)},expression:"formSubmit.date_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tanggal Lahir Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tempat Lahir"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Tempat Lahir"},model:{value:(_vm.formSubmit.place_of_birth),callback:function ($$v) {_vm.$set(_vm.formSubmit, "place_of_birth", $$v)},expression:"formSubmit.place_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tempat Lahir Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.formSubmit.email),callback:function ($$v) {_vm.$set(_vm.formSubmit, "email", $$v)},expression:"formSubmit.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Email Wajib Diisi & Format Email Harus Benar" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Telepon"}},[_c('validation-provider',{attrs:{"name":"Nomor Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nomor Telepon"},model:{value:(_vm.formSubmit.phone),callback:function ($$v) {_vm.$set(_vm.formSubmit, "phone", $$v)},expression:"formSubmit.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nomor Telepon Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gaji Pokok"}},[_c('validation-provider',{attrs:{"name":"Gaji Pokok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Gaji Pokok"},on:{"input":_vm.handleInputHarga},model:{value:(_vm.formSubmit.basic_salary),callback:function ($$v) {_vm.$set(_vm.formSubmit, "basic_salary", $$v)},expression:"formSubmit.basic_salary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Gaji Pokok Wajib Diisi" : ""))]),(_vm.formSubmit.basic_salary)?_c('small',[_vm._v(" Rp. "+_vm._s(_vm.price2)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Posisi/Jabatan"}},[_c('validation-provider',{attrs:{"name":"Posisi/Jabatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.positionOptions,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"Pilih Posisi/Jabatan"},model:{value:(_vm.formSubmit.position),callback:function ($$v) {_vm.$set(_vm.formSubmit, "position", $$v)},expression:"formSubmit.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Posisi/Jabatan Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Catatan"}},[_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Masukkan Catatan"},model:{value:(_vm.formSubmit.note),callback:function ($$v) {_vm.$set(_vm.formSubmit, "note", $$v)},expression:"formSubmit.note"}})],1)],1),(_vm.formSubmit.position === "MARKETING")?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Target Point"}},[_c('b-form-input',{attrs:{"placeholder":"Target Point"},model:{value:(_vm.formSubmit.target_point),callback:function ($$v) {_vm.$set(_vm.formSubmit, "target_point", $$v)},expression:"formSubmit.target_point"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(this.errorsTargetPoint ? "Target Point Wajib Diisi dan Lebih Dari 0" : "")+" ")])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","align":"end"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.backButton()}}},[_vm._v(" Kembali ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitValidate.apply(null, arguments)}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }