<template>
  <b-card-code title="Form Data Karyawan">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nama Lengkap">
              <validation-provider
                #default="{ errors }"
                name="Nama Lengkap"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.name"
                  placeholder="Nama Lengkap"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Lengkap Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Alamat Lengkap">
              <validation-provider
                #default="{ errors }"
                name="Alamat Lengkap"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.address"
                  placeholder="Alamat Lengkap"
                />
                <small class="text-danger">{{
                  errors[0] ? "Alamat Lengkap Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Lahir"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.date_of_birth"
                  class="form-control"
                  placeholder="Pilih Tanggal Lahir"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tempat Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.place_of_birth"
                  placeholder="Tempat Lahir"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tempat Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input v-model="formSubmit.email" placeholder="Email" />
                <small class="text-danger">{{
                  errors[0]
                    ? "Email Wajib Diisi & Format Email Harus Benar"
                    : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nomor Telepon">
              <validation-provider
                #default="{ errors }"
                name="Nomor Telepon"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.phone"
                  placeholder="Nomor Telepon"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nomor Telepon Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Gaji Pokok">
              <validation-provider
                #default="{ errors }"
                name="Gaji Pokok"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.basic_salary"
                  placeholder="Gaji Pokok"
                  @input="handleInputHarga"
                />
                <small class="text-danger">{{
                  errors[0] ? "Gaji Pokok Wajib Diisi" : ""
                }}</small>

                <small v-if="formSubmit.basic_salary"> Rp. {{ price2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Posisi/Jabatan">
              <validation-provider
                #default="{ errors }"
                name="Posisi/Jabatan"
                rules="required"
              >
                <v-select
                  v-model="formSubmit.position"
                  :options="positionOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="Pilih Posisi/Jabatan"
                />
                <small class="text-danger">{{
                  errors[0] ? "Posisi/Jabatan Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formSubmit.note"
                placeholder="Masukkan Catatan"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="formSubmit.position === `MARKETING`">
            <b-form-group label="Target Point">
              <b-form-input
                v-model="formSubmit.target_point"
                placeholder="Target Point"
              />
              <small class="text-danger">
                {{
                  this.errorsTargetPoint
                    ? "Target Point Wajib Diisi dan Lebih Dari 0"
                    : ""
                }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- button -->
        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitValidate"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      positionOptions: [],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      errorsTargetPoint: false,
      lastPathParams: "",

      price2: "",

      formSubmit: {
        name: "",
        address: "",
        phone: "",
        email: "",
        date_of_birth: "",
        place_of_birth: "",
        note: "",
        position: "",
        basic_salary: "",
        target_point: 0,
      },

      id_employee: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",
      // end
    };
  },

  beforeMount() {
    // this.getPosition();

    this.lastPathParams = window.location.pathname.split("/").pop();
    this.getComboPosisi();

    if (this.lastPathParams != "form") {
      this.getIdData();
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getComboPosisi() {
      return new Promise(() => {
        axios.get("v1/employee/combo/position").then((res) => {
          this.positionOptions = res.data;
        });
      });
    },

    submitValidate() {
      if (
        this.formSubmit.position === "MARKETING" &&
        this.formSubmit.target_point === ""
      ) {
        this.errorsTargetPoint = true;
      } else if (
        this.formSubmit.position === "MARKETING" &&
        this.formSubmit.target_point == 0
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Target point harus lebih dari 0",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        this.errorsTargetPoint = false;
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            if (this.lastPathParams === "form") {
              return new Promise(() => {
                axios
                  .post("v1/employee", this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Tambah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Tambah Data Karyawan Berhasil`,
                      },
                    });
                    this.$router.push("/karyawan");
                    this.$refs["ref-form-data-kavling"].hide();
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                    }
                  });
              });
            } else {
              return new Promise(() => {
                axios
                  .put("v1/employee/" + this.$route.params.id, this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Ubah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Ubah Data Karyawan Berhasil`,
                      },
                    });
                    this.$router.push("/karyawan");
                    this.$refs["ref-form-data-kavling"].hide();
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                    }
                  });
              });
            }
          }
        });
      }
    },

    getIdData() {
      return new Promise(() => {
        axios
          .get("v1/employee/" + this.$route.params.id)
          .then((res) => {
            this.formSubmit.name = res.data.name;
            this.formSubmit.address = res.data.address;
            this.formSubmit.phone = res.data.phone;
            this.formSubmit.email = res.data.email;
            this.formSubmit.date_of_birth = res.data.date_of_birth;
            this.formSubmit.place_of_birth = res.data.place_of_birth;
            this.formSubmit.note = res.data.note;
            this.formSubmit.position = res.data.position;
            this.formSubmit.basic_salary = res.data.basic_salary;
            this.formSubmit.target_point = res.data.target_point;

            this.handleInputHarga();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    // getPosition() {
    //   return new Promise(() => {
    //     axios.get("v1/position/combo").then((res) => {
    //       this.positionOptions = res.data;
    //     });
    //   });
    // },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.basic_salary);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    backButton() {
      this.$router.push("/karyawan");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
